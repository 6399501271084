<template>
  <div id="search-autocomplete-container">
    <Search />
  </div>
</template>

<script>
import Search from './components/Search.vue';
import './global.css';

export default {
  name: 'App',
  components: {
    Search
  }
}
</script>

<style scoped>
*, *:before, *:after {
  box-sizing: border-box;
}
</style>

<style>
#search-autocomplete-container {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #2c3e50;
  font-family: var(--font-family-base);
  max-width: 620px;
}

@media only screen and (max-width: 767px) {
  .catalog-product-view:not(.search-active) #search-autocomplete {
    display: none;
  }

  /* Double root selector is a specificity hack to be greater than the :not above */
  .mmactive.mmactive #search-autocomplete,
  .autocomplete-open.autocomplete-open #search-autocomplete {
    display: block;
  }
}
</style>
