<template>
  <div class="autocomplete-dropdown" id="autocomplete-dropdown" data-cnstrc-autosuggest>
    <div class="autocomplete-wrapper">
      <div class="suggestion-container" :class="suggestionsClass">

        <div id="recently_searched" class="recently_searched" v-if="getRecentlySearched().length === 3 && !searchTerm.length">
          <ul role="listbox" class="recent">
            <li role="title">Recently Searched</li>
            <li v-for="suggestion in getRecentlySearched()" :key="suggestion" role="option">
              <a
                @click="recentHandler(suggestion, $event)"
                :href="getSearchSuggestionURL(suggestion)"
                class="suggestion"
                v-html="suggestion"
              ></a>
            </li>
          </ul>

          <ul
            role="listbox"
            class="trending"
            v-bind="recommenderTrackingAttributesForTermsContainer"
          >
            <li role="title">Trending Searches</li>
            <li
              :key="suggestion.data.id" role="option"
              v-bind="suggestion.attrs"
              v-for="suggestion in termsWithAttributes"
            >
              <a
                @click="trendingHandler(suggestion.value, $event)"
                :href="getSearchSuggestionURL(suggestion.value)"
                class="suggestion"
                v-html="suggested ? suggestion.value : highlightTerm(suggestion)"
              ></a>
            </li>
          </ul>
        </div>

        <div id="no-results-term" v-if="isSkuSearch(searchTerm)">
          <a :href="noResultsLink()" v-html="skuSearch(searchTerm)"></a>
        </div>

        <div id="autocomplete" v-else-if="getSuggests().length && getRecentlySearched().length < 3 || getSuggests().length && searchTerm.length">
          <ul
            role="listbox"
            v-bind="recommenderTrackingAttributesForTermsContainer"
          >
            <li role="title" v-if="suggested">Trending Searches</li>
            <li
              :key="suggestion.data.id" role="option"
              v-bind="suggestion.attrs"
              v-for="suggestion in termsWithAttributes"
            >
              <a
                @click="trendingHandler(suggestion.value, $event)"
                :href="getSearchSuggestionURL(suggestion.value)"
                class="suggestion"
                v-html="suggested ? suggestion.value : highlightTerm(suggestion)"
              ></a>
            </li>
          </ul>
        </div>

        <div id="no-results-term" v-else-if="searchTerm.length && !(suggestions.length)">
            <p v-if="this.products.length !== 1 || this.searchTerm.length < 3">Search for</p>
            <a :href="noResultsLink()" v-html="skuSearch(searchTerm)"></a>
        </div>
      </div>

      <div id="products" v-if="productsWithAttributes.length">
        <span class="section-title">{{ getHeading() }}</span>
        <ul
          role="listbox"
          v-bind="recommenderTrackingAttributesForProductContainer"
        >
          <li
            :key="product.data.id"
            v-bind="product.attrs"
            v-for="product in productsWithAttributes"
          >
            <a
              @click="productHandler(product)"
              :href="product.data.url"
            >
              <div class="aspect-ratio-3x4">
                <img :src="productImage(product.data.image_url)" :alt="product.data.description" />
              </div>
              <span class="product-title">{{ product.data.brand_collection }}<br>{{ product.value }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Autocomplete",
  props: {
    onSearchPage: {
      type: Boolean,
      required: true
    },
    products: {
      type: Array,
      required: true
    },
    responseTopProducts: {
      type: Object,
      require: false,
    },
    responseTopSuggestions: {
      type: Object,
      require: false,
    },
    searchTerm: {
      type: String,
      required: false
    },
    suggestions: {
      type: Array,
      required: true
    },
    suggestionsClass: {
      type: String,
      required: false
    }
  },
  methods: {
    highlightTerm(suggestion) {
      // Is a string before the 3 limit threshold is met or if there are no results for a term (e.g. aaaaa)
      // Returning the value achieves the desired effect without causing suggestion.value.split to break vue.
      if (typeof(suggestion) !== 'object') {
        return suggestion;
      }

      let data = suggestion.data,
          output = '';

      if (null === data) {
        return suggestion.value;
      }

      let splitValue = suggestion.value.split(/([\s_\-.()/,]+)/),
          value;

      for (let term = this.searchTerm || ''; splitValue.length > 0; ) {
        let searchTerm = suggestion.matched_terms ? suggestion.matched_terms.slice() : term.split(/[\s\-_.]+/);
        for (value = splitValue.shift(); searchTerm.length > 0; ) {
          let search = searchTerm.shift();

          if (0 === value.toLowerCase().indexOf(search.toLowerCase())) {
            let escapeSearch = search.replace(/[-[]\/{}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&"),
                regex = new RegExp('('.concat(escapeSearch, ')(.*)'), 'i');

            value = value.replace(regex, '</strong>$1<strong>$2');
          }
        }

        output += value;

        if (splitValue.length > 0) {
          output += splitValue.shift();
        }
      }

      return '<strong>'.concat(output, '</strong>').replace(/<strong><\/strong>/g, '');
    },
    getHeading() {
      return window.search.heading;
    },
    getConfig() {
      return window.search.config;
    },
    getRecentlySearched() {
      // Don't try to shorten it to
      // JSON.parse(localStorage.getItem("recently_searched")).reverse()
      // even tho that will work locally.  Something about it breaks the
      // component when building for production without error.
      let recently_searched = JSON.parse(localStorage.getItem("recently_searched")) || [];
      return recently_searched.length ? recently_searched.reverse() : [];
    },
    getSuggests() {
      let config = this.getConfig(),
          sectionKey = this.isMobile() ? 'mobile' : 'desktop',
          length = config[sectionKey].search;

      return this.suggestions.slice(0, +length);
    },
    isMobile() {
      return window.matchMedia('(max-width: 767px)').matches;
    },
    getSearchSuggestionURL(suggestion) {
      let url = new URL(window.location.href);

      url.pathname = '/catalogsearch/result/';
      url.searchParams.set('q', suggestion);
      return url.toString();
    },
    recentHandler(suggestion, event) {
      this.$emit('recentClick', suggestion, event);
    },
    trendingHandler(suggestion, event) {
      this.$emit('suggestionClick', suggestion, event);
    },
    productHandler(product) {
      let selectedSwatch = {},
        swatchOptions = product?.data?.swatch_options?.[206];

      if (!swatchOptions) {
        this.$emit('productClick');
        return;
      }

      let swatchData = Object.values(swatchOptions)
        .filter((so) => so.label === product.data.color)
        .pop();

      // The products in the API response will sometimes be the parent product
      // and sometimes be the child products.  Parent products don't always
      // have "Color" set; so product.data.color will not be present on those.
      // When that happens the filter returns nothing.  This check is here to
      // prevent an error when trying to set the swatchData, M2PL-5345:
      // Cannot read properties of undefined (reading 'option_id')
      if (swatchData) {
        selectedSwatch[product.data.id] = swatchData.option_id;
      }

      document.cookie = 'selected_swatch=' + encodeURIComponent(JSON.stringify(selectedSwatch)) + ";path=/";

      if (this.searchTerm) {
        this.$emit('productClick');
      }
    },
    productImage(image) {
      let url = new URL(image);

      if (!url.origin.includes('cloudinary')) {
        // Placeholder
        return 'https://res.cloudinary.com/scrubsandbeyond/image/upload/c_pad,e_bgremoval,f_auto,h_564,q_auto,w_422/b_rgb:f3f3f3/search/placeholder.jpg';
      }

      return image;
    },
    skuSearch(searchTerm) {
      let output;

      if (searchTerm.length < 3) {
        return searchTerm;
      }

      if (this.products.length !== 1) {
        return searchTerm;
      }

      if (this.products[0].data.id.toLowerCase().includes(searchTerm.toLowerCase())) {
        output = this.products[0].data.id + ' - <strong>' + this.products[0].data.brand_collection + ' ' + this.products[0].value + '</strong>';
      }

      return output;
    },
    noResultsLink() {
      let link = this.getSearchSuggestionURL(this.searchTerm);

      if (this.products.length !== 1) {
        return link;
      }

      if (this.searchTerm.toLowerCase() === this.products[0].data.id.toLowerCase()) {
        link = this.products[0].data.url;
      }

      return link;
    },
    isSkuSearch(searchTerm) {
      let found = this.products.find((product) => {
        return product.data.id.toLowerCase() === searchTerm.toLowerCase();
      });

      return !!found;
    }
  },
  computed: {
    suggested() {
      // Return true if suggest value is a recommended suggestion
      return this.getSuggests().length && !this.getRecentlySearched().length < 3 && !this.searchTerm.length;
    },
    recommenderTrackingAttributesForTermsContainer() {
      let attrs = {};

      if (this.searchTerm.length) {
        return attrs;
      }

      if (! this.responseTopSuggestions?.response) {
        return attrs;
      }

      // https://docs.constructor.io/integration/tracking/#recommendations
      attrs['data-cnstrc-recommendations'] = '';
      attrs['data-cnstrc-recommendations-pod-id'] = this.responseTopSuggestions.response.pod.id;
      attrs['data-cnstrc-result-id'] = this.responseTopSuggestions.result_id;
      attrs['data-cnstrc-num-results'] = this.suggestions.length;

      return attrs;
    },
    termsWithAttributes() {
      let config = this.getConfig(),
          length,
          optionKey = 'withRecent',
          sectionKey = this.isMobile() ? 'mobile' : 'desktop';

      if (this.searchTerm.length || !this.searchTerm.length && this.getRecentlySearched().length < 3) {
        optionKey = 'search';
      }

      length = config[sectionKey][optionKey];

      return this.suggestions.slice(0, +length).map((t) => {
        let attrs = {};

        attrs['data-cnstrc-item-name'] = t.value;

        // Tracking data for Autosuggest
        // https://docs.constructor.io/integration/tracking/#autosuggest
        if (this.searchTerm.length) {
          attrs['data-cnstrc-item-section'] = 'Search Suggestions';
        }

        // Tracking data for Recommendation
        // https://docs.constructor.io/integration/tracking/#recommendations
        if (! this.searchTerm.length) {
          attrs['data-cnstrc-item'] = 'Recommendation';
          // Not every term has a strategy
          if (t.strategy) {
            attrs['data-cnstrc-strategy-id'] = t.strategy?.id;
          }
          attrs['data-cnstrc-item-id'] = t.data.id;
        }

        t.attrs = attrs;

        return t;
      });
    },
    productsWithAttributes() {
      let config = this.getConfig(),
          sectionKey = this.isMobile() ? 'mobile' : 'desktop',
          length = config[sectionKey].visual;

      return this.products.slice(0, +length).map((p) => {
        let attrs = {
          'role': 'option'
        };

        attrs['data-cnstrc-item-name'] = p.value;
        attrs['data-cnstrc-item-id'] = p.data.id;

        // Tracking data for Autosuggest
        // https://docs.constructor.io/integration/tracking/#autosuggest
        if (this.searchTerm.length) {
          attrs['data-cnstrc-item-section'] = 'Products';
        }

        // Tracking data for Recommendation
        // https://docs.constructor.io/integration/tracking/#recommendations
        if (! this.searchTerm.length) {
          attrs['data-cnstrc-item'] = 'Recommendation';
          // Not ever product has a strategy
          if (p.strategy) {
            attrs['data-cnstrc-strategy-id'] = p.strategy?.id;
          }
        }

        p.attrs = attrs;

        return p;
      });
    },
    recommenderTrackingAttributesForProductContainer() {
      // If there is a search term it is displaying Autosuggest results
      // Autosuggest doesn't have tracking attributes on the result's
      // immediate parent.
      if (this.searchTerm.length) {
        return null;
      }

      if (! this.responseTopProducts?.response) {
        return null;
      }

      // https://docs.constructor.io/integration/tracking/#recommendations
      return {
        'data-cnstrc-recommendations': '',
        'data-cnstrc-recommendations-pod-id': this.responseTopProducts.response.pod.id,
        'data-cnstrc-result-id': this.responseTopProducts.result_id,
        'data-cnstrc-num-results': this.responseTopProducts.response.total_num_results
      };
    }
  }
}
</script>

<style scoped>
.autocomplete-dropdown {
  background: var(--color-white);
  left: 0;
  line-height: 1.2;
  position: absolute;
  top: 100%;
  width: 100vw;
  z-index: 1000;
}

@media only screen and (max-width: 767px) {
  .open .autocomplete-dropdown {
    top: 61px; /* Magic number from 45px of text input and 16px of padding */
    height: calc(100vh - 118px); /* Magic number from testing */
    overflow: scroll;
    padding-top: 19px;
  }
}
@media only screen and (min-width: 768px) {
  .autocomplete-dropdown {
    border-top: solid 1px #cfcfcf;
  }
}

.autocomplete-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 1280px;
}

@media only screen and (min-width: 768px) {
  .autocomplete-wrapper {
    flex-direction: row;
    padding: 35px 35px 35px 55px; /* Magic Number 55 is based on logo size */
  }
}

@media only screen and (min-width: 1024px) {
  div.autocomplete-wrapper {
    padding: 35px 35px 35px 165px; /* Magic Number 165 is based on logo size */
  }
}

@media only screen and (min-width: 768px) {
  .suggestion-container {
    flex: 1 0 25%;
    max-width: 325px;
  }
}

#products {
  flex: 1 1 auto;
}

img {
  max-width: 100%;
}

[role="listbox"] {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

.trending {
  margin-top: 2rem;
}

[role="listbox"] li {
  margin-bottom: 0; /* Coming from somewhere in Magento */
}

.section-title,
[role="listbox"] [role="title"] {
  color: var(--color-black);
  flex: 1;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  padding: 0 35px;
  text-align: left;
}

@media only screen and (min-width: 768px) {
  .section-title,
  [role="listbox"] [role="title"] {
    border-bottom: 0;
    padding: 0 35px 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  div#products ul[role=listbox],
  span.section-title {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  div#products ul[role=listbox] {
    gap: 20px !important;
  }
}

.section-title {
  display: block;
}

@media only screen and (max-width: 767px) {
  .suggestion-container [role="listbox"] [role="title"] {
    border-bottom: 1px solid #b9b9b9;
    padding: 0 37px 12px;
  }
}

[role="listbox"] [role="option"] .suggestion {
  color: var(--color-dark-gray);
  cursor: pointer;
  display: block;
  font-size: var(--font-size-small);
  padding: 10px 35px 10px 43px;
  position: relative;
  text-decoration: none;
}

@media only screen and (min-width: 767px) {
  [role="listbox"] [role="option"] .suggestion {
    padding: 12px 35px;
  }
}

[role="listbox"] [role="option"] .suggestion:hover,
[role="listbox"] [role="option"] .suggestion:focus {
  background-color: #D6D7D9;
}
[role="listbox"] [role="option"] .suggestion:focus-visible,
[role="listbox"] [role="option"] a:focus-visible,
[id="no-results-term"] a:focus-visible {
  outline: var(--color-blue) auto 1px;
}

.recently_searched .recent [role="option"] .suggestion:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjguNSIgc3Ryb2tlPSIjNzk3OTc5Ii8+CjxwYXRoIGQ9Ik05IDNWMTBIMTUiIHN0cm9rZT0iIzc5Nzk3OSIgc3Ryb2tlLWxpbmVqb2luPSJiZXZlbCIvPgo8L3N2Zz4K);
  background-size: contain;
  content: '';
  display: block;
  height: 18px;
  left: 15px;
  margin-top: -9px;
  position: absolute;
  top: 50%;
  width: 18px;
}

@media only screen and (min-width: 767px) {
  .recently_searched [role="listbox"] [role="option"] .suggestion:before {
    left: 8px;
  }
}

#products [role="listbox"] {
  gap: 35px;
  padding: 0 35px;
}

@media only screen and (max-width: 767px) {
  #products [role="listbox"] {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    padding: 15px 35px 35px;
  }
}

@media only screen and (min-width: 768px) {
  #products [role="listbox"] {
    display: flex;
  }
}

#products [role="listbox"] [role="option"] {
  flex: 0 1 33.33%;
}

#products [role="listbox"] [role="option"] a {
  color: var(--color-dark-gray);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-normal);
  letter-spacing: 0.2px;
  line-height: 1.3;
  text-align: left;
  text-decoration: none;
}

.aspect-ratio-3x4 {
  margin-bottom: 15px;
  padding-bottom: 133.33333%;
  position: relative;
  width: 100%;
}

#products [role="listbox"] [role="option"] img {
  position: absolute;
  inset: 0;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.product-title:hover {
  text-decoration: underline;
}

[id="no-results-term"] {
  color: var(--color-dark-gray);
  padding: 0 35px;
  word-break: break-word;
}

[id="no-results-term"] p {
  margin: 0;
}

[id="no-results-term"] a {
  color: var(--color-dark-gray);
  text-decoration: none;
}
</style>

<!-- This CSS can't be scoped because the strong tags are added dynamically -->
<style>
[role="listbox"] [role="option"] .suggestion strong,
#no-results-term strong {
  color: var(--color-black);
}
</style>
