<template>
  <div class="search-autocomplete-wrapper" :class="{open: isAutocompleteVisible}">
    <form @submit.prevent="debounceInput" class="form minisearch" id="search_mini_form" @keyup.escape.prevent="hideAutocomplete()">
      <div class="field search" @keydown.up.prevent="handleKeyDown($event)" @keydown.down.prevent="handleKeyDown($event)">
        <label class="label" for="search" data-rote="minisearch-label">
          <span>Search</span>
        </label>

        <div class="control-background-wrapper">
          <div class="control">
            <input
              @click="greet"
              @keyup="debounceInput"
              aria-autocomplete="both"
              aria-haspopup="false"
              autocomplete="off"
              class="input-text"
              data-cnstrc-search-input
              id="search"
              maxlength="128"
              name="q"
              placeholder="Search products, colors, or brands"
              ref="search"
              type="text"
              v-model="searchTerm"
            />
            <span role="presentation" class="icon-search"></span>
            <button @click="clear" type="button" aria-label="close search" class="icon-close"></button>
          </div>
        </div>

        <Autocomplete
            @recentClick="updateSearchTerm"
            @suggestionClick="updateSearchTerm"
            @productClick="setRecentlySearched"
            v-show="isAutocompleteVisible"
            :on-search-page="onSearchPage()"
            :products="products"
            :response-top-products="responseTopProducts"
            :response-top-suggestions="responseTopSuggestions"
            :search-term="searchTerm.trim()"
            :suggestions="suggestions"
            :suggestions-class="suggestionsClass"
          />
      </div>
    </form>

    <Teleport to=".page-wrapper">
      <div id="autocomplete-overlay"></div>
    </Teleport>
  </div>
</template>

<script>

import Autocomplete from "./Autocomplete.vue";

import ConstructorIOClient from '@constructor-io/constructorio-client-javascript';
import debounce from 'lodash/debounce';

export default {
  name: "Search",
  components: {
    Autocomplete: Autocomplete
  },
  data() {
    return {
      index: -1,
      isAutocompleteVisible: false,
      previousIndex: null,
      previousSearchTerm: '',
      previousProductStore: '',
      previousSuggestionsStore: '',
      products: [],
      responseTopProducts: null,
      responseTopSuggestions: null,
      searchTerm: '',
      speechService: {},
      suggestions: [],
      suggestionsClass: '',
      topProductsStore: [],
      topSuggestionsStore: [],
      showIcon: true
    }
  },
  created() {
    this.searchTerm = this.getSearchTermFromURL();

    this.debounceInput = debounce(this.input, 300);

    document.onclick = this.clickOutside;

    window['cnstrc-data'] = window['cnstrc-data'] || {};
  },
  methods: {
    clickOutside(event) {
      // If the modal isn't open, we don't need to worry about closing it. exit.
      if (this.isAutocompleteVisible === false) {
        return;
      }

      // If we're opening autocomplete from mobile PDP search icon
      if (event.target === document.querySelector('button.icon-open')) {
        return;
      }

      // .isTrigger is a jQuery property that does not appear on browser
      // generated events. If it is appearing in the event, we can safely
      // assume we're outside of the autocomplete because it uses no jQuery.
      // This check is here because jQuery generated events don't have
      // .closest on event.target and throw an error.
      // If #search_mini_form is a parent or child node of the event target
      // then we can assume we're clicking somewhere inside of the scope of
      // autocomplete and therefore don't need to do anything
      if (event.isTrigger === undefined &&
          event.target.closest('#search_mini_form') !== null) {
        return;
      }

      this.clear();
    },
    clear() {
      this.products = [];
      this.suggestions = [];
      this.suggestionsClass = '';
      this.searchTerm = '';
      this.hideAutocomplete();
    },
    getSearchClient() {
      let searchClientConfig = {
        apiKey: window.search.apiKey,
        fetch: fetch
      };

      if (window.cnstrcUserId) {
        searchClientConfig.userId = window.cnstrcUserId;
      } else {
        let mageCacheStorage = JSON.parse(localStorage.getItem('mage-cache-storage'));

        if (mageCacheStorage?.customer?.id) {
          searchClientConfig.userId = mageCacheStorage.customer.id;
        }
      }

      return new ConstructorIOClient(searchClientConfig);
    },
    input(event) {
      let term = this.searchTerm.trim();

      if (event.key === 'Enter' && term.length > 0) {
        Promise.allSettled([this.search()])
        .then(() => {
          this.setRecentlySearched();
          let loc;

          let skuSearch = this.products.find((product) => {
            return product.data.id.toLowerCase() === term.toLowerCase();
          });

          if (skuSearch) {
            loc = this.products[0].data.url;
          } else {
            let url = new URL(window.location.origin + '/catalogsearch/result/');
            url.searchParams.append('q', term);
            loc = url.toString();
          }

          if (loc) {
            location.assign(loc);
          }
        });

        return;
      }

      // We don't want to trigger search which will open the autocomplete when
      // we're trying to close the autocomplete with the escape key.
      if(event.key !== 'Escape' && event.key !== 'Esc') {
        this.search();
      }
    },
    search() {
      let autocomplete = this.getSearchClient().autocomplete,
          sectionKey = this.isMobile() ? 'mobile' : 'desktop',
          self = this;

      // Prevent empty searches
      if (this.searchTerm.trim() === '') {
        this.setTopSuggestionsAndProducts();
        return;
      }

      // Prevent Redunant AJAX calls
      if (this.searchTerm === this.previousSearchTerm &&
      (this.previousProductStore.length !== 0 && this.previousSuggestionsStore.length !== 0)) {
        this.products = this.previousProductStore;
        this.suggestions = this.previousSuggestionsStore;
        this.showAutocomplete();
        return;
      }

      return autocomplete.getAutocompleteResults(
          this.searchTerm,
          {
            resultsPerSection: {
              Products: window.search.config[sectionKey].visual,
              'Search Suggestions': window.search.config[sectionKey].search,
            }
          }
      ).then(function (json) {
        if (json.sections.Products.length === 0) {
          // Check if topProductsStore has products.  This will be populated if
          // greet has fired but not if we start on the search results page
          // and the input has been pre-populated by the q param.
          if (self.topProductsStore.length > 0) {
            self.products = self.topProductsStore;
          } else {
            self.getTopProducts();
          }

          self.suggestions = [];
        } else {
          self.products = json.sections.Products || [];
          self.suggestions = json.sections['Search Suggestions'] || [];

          // Cache previous results to be used if the user clicks out of
          // autocomplete and back in.
          self.previousSearchTerm = self.searchTerm;
          self.previousProductStore = self.products;
          self.previousSuggestionsStore = self.suggestions;
        }

        self.suggestionsClass = 'autocomplete-suggestions';

        self.showAutocomplete();
      });
    },
    greet(event) {
      event.target.select();

      if (this.isAutocompleteVisible) {
        return;
      }

      if (this.searchTerm === '') {
        this.setTopSuggestionsAndProducts();
      } else {
        this.search();
      }
    },
    getTopSuggestions() {
      let recommendations = this.getSearchClient().recommendations,
          sectionKey = this.isMobile() ? 'mobile' : 'desktop',
          self = this;

      // Get Top Search Suggestions Pod
      return recommendations.getRecommendations(
          'top_search_terms',
          {
            numResults: window.search.config[sectionKey].search,
            section: 'Search Suggestions'
          }
      ).then(function (json) {
        self.responseTopSuggestions = json;
        self.suggestions = json.response.results || [];
        self.topSuggestionsStore = json.response.results || [];
        self.suggestionsClass = 'recommendation-suggestions';
        self.suggestionsSearchPod = json.response.pod?.id || '';
        self.suggestionsSearchInitialPod = self.suggestionsSearchInitialPod || json.response.pod?.id || '';
      });
    },
    getTopProducts() {
      let recommendations = this.getSearchClient().recommendations,
          sectionKey = this.isMobile() ? 'mobile' : 'desktop',
          self = this;

      // Get Top Products Pod
      return recommendations.getRecommendations(
          'top_products',
          {
            numResults: window.search.config[sectionKey].visual,
            section: 'Products'
          }
      ).then(function (json) {
        self.responseTopProducts = json;
        self.products = json.response.results || [];
        self.topProductsStore = json.response.results || [];
      });
    },
    setTopSuggestionsAndProducts() {
      let callsToMake = [];

      if (this.topProductsStore.length === 0) {
        callsToMake.push[this.getTopProducts()];
      } else {
        this.products = this.topProductsStore;
      }

      if (this.topSuggestionsStore.length === 0) {
        callsToMake.push[this.getTopSuggestions()];
      } else {
        this.suggestions = this.topSuggestionsStore;
      }

      Promise.all(callsToMake).then(() => {
        this.showAutocomplete();
      });
    },
    handleKeyDown(event) {
      /**
       * Has the user clicked the up/down arrow keys? N - return
       * Are there suggested terms to navigate through? N - return
       * Get current focus
       * Is current focus in this search container? N - return
       * Are we at beginning/end of suggested terms?
       *   Y - B: Move back to Search input,
       *       E: Are there suggested products? Y: Products N: Search?
       *   N - Move focus up/down list of terms
       */

      if (event.key !== 'ArrowDown' && event.key !== 'ArrowUp') {
        return;
      }

      let dataLayer = window.dataLayer || false;
      if (dataLayer) {
        dataLayer.push({
          'event': 'autocompleteKeyDown',
          'key': event.key
        });
      }

      this.updateIndex(event.key);
    },
    updateIndex(key) {
      if (this.suggestions.length === 0) {
        return;
      }

      let lastIndex = this.suggestions.length;

      this.previousIndex = this.index;

      if (key === 'ArrowUp') {
        this.index--;
      } else if (key === 'ArrowDown') {
        this.index++;
      }

      // index being -1 means that it is focused on input#search.
      // Catch anything lower than that and route it back to the bottom
      if (this.index < -1) {
        this.index = lastIndex;
      }

      // index being 0 means we're on the title
      // increment to get to the first real result
      if (this.index === 0) {
        this.index++;
      }

      // index being larger than the number of suggestions means that we
      // need to loop back to the top.  -1 is input#search.
      if (this.index > lastIndex) {
        this.index = -1;
      }
    },
    updateSearchTerm(suggestion, event) {
      if (suggestion) {
        this.setRecentlySearched(suggestion);
      } else {
        this.setRecentlySearched();
      }

      if(this.onSearchPage()) {
        let term = suggestion || this.searchTerm;
        event.preventDefault();
        this.updateUrl(term);
       } else {
        this.search();
       }
    },
    hideAutocomplete() {
      document.querySelector('body').classList.remove('autocomplete-open');
      document.querySelector('#autocomplete-overlay').classList.remove('show');
      this.isAutocompleteVisible = false;
    },
    showAutocomplete() {
      document.querySelector('body').classList.add('autocomplete-open');
      document.querySelector('#autocomplete-overlay').classList.add('show');
      this.isAutocompleteVisible = true;
    },
    setFocus() {
      this.setTabIndex();

      let autocompleteContainer = document.querySelector('.autocomplete-dropdown [role="listbox"]');

      // Back to search
      if (this.index === -1) {
        document.getElementById('search').focus();
      } else {
        autocompleteContainer.children[this.index].children[0].focus();
      }
    },
    setTabIndex() {
      let autocompleteContainer = document.querySelector('.autocomplete-dropdown [role="listbox"]');

      if (this.index > -1) {
        autocompleteContainer.children[this.index].children[0].setAttribute('tabindex', '-1');
      }

      if (this.previousIndex > -1) {
        autocompleteContainer.children[this.previousIndex].children[0].removeAttribute('tabindex');
      }
    },
    isMobile() {
      return window.matchMedia('(max-width: 767px)').matches;
    },
    onSearchPage() {
      // Check if on catalog search page
      let searchPath = '/catalogsearch/result/';
      return window.location.pathname.startsWith(searchPath);
    },
    shouldShowPDPSearch() {
      if (!this.isMobile()) {
        return false;
      }

      return document.querySelector('#pdp-search-icon') !== null;
    },
    updateUrl(suggestion) {
      const url = new URL(window.location);

      url.searchParams.set('q', suggestion);
      window.history.pushState({ searchParamChange: true, updateUrl: false }, '', url);

      this.hideAutocomplete();
    },
    setRecentlySearched(searchTerm = this.searchTerm) {
      let recently_searched = JSON.parse(localStorage.getItem("recently_searched")) || [];

      if (searchTerm.length !== 0 && /^\s*$/.test(searchTerm) === false) {
        if (recently_searched.indexOf(searchTerm) === -1) {
          if (recently_searched.length === 3) {
            recently_searched.shift();
          }
          recently_searched.push(searchTerm)
        } else {
          for (let i = recently_searched.length - 1; i >= 0; i--) {
            if (recently_searched[i] === searchTerm) {
              recently_searched.splice(i, 1);
              recently_searched.push(searchTerm);
            }
          }
        }
      }

      localStorage.setItem("recently_searched", JSON.stringify(recently_searched))
    },
    getRecentlySearched() {
      // Don't try to shorten it to
      // JSON.parse(localStorage.getItem("recently_searched")).reverse()
      // even tho that will work locally.  Something about it breaks the
      // component when building for production without error.
      let recently_searched = JSON.parse(localStorage.getItem("recently_searched")) || [];
      return recently_searched.length ? recently_searched.reverse() : [];
    },
    getSearchTermFromURL() {
      let searchParams = new URLSearchParams(window.location.search);
      let q = searchParams.get('q') || '';

      return q;
    }
  },
  watch: {
    index() {
      this.setFocus();
    },
  }
}
</script>

<style scoped>
.search-autocomplete-wrapper {
  width: 100%;
  background-color: var(--color-white);
}
@media only screen and (max-width: 767px) {
  .search-autocomplete-wrapper {
    bottom: 10px;
    left: 0;
    position: absolute;
    width: 100vw;
  }
}
@media only screen and (max-width: 767px) {
  .search-autocomplete-wrapper.open {
    bottom: 0;
    left: 0;
    padding-top: 10px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 9999;
  }
}
#search_mini_form {
  width: 100%;
  margin: 0;
}
#search_mini_form label[for="search"]{
  display: none;
}
#search_mini_form input#search {
  box-sizing: border-box;
  height: 45px;
  border-radius: 50px;
  width: 100%;
  outline: none;
  padding: 0 5px 0 45px;
  border: 1px solid #4c4c4c;
  color: var(--color-dark-gray);
  font-size: var(--font-size-medium);
}
#search_mini_form input#search::placeholder {
  font-size: var(--font-size-small);
  color: var(--color-dark-gray);
}
@media only screen and (min-width: 1024px) {
  #search_mini_form input#search {
    padding-right: 45px;
  }
}
#search_mini_form .control {
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .open #search_mini_form .control {
    margin-left: 0;
    margin-right: 54px;
  }

  .open #search_mini_form .control-background-wrapper {
    background: var(--color-white);
    padding: 0 10px 19px;
    position: relative;
    z-index: 1001;
  }
}
#search_mini_form .icon-close,
#search_mini_form .icon-search {
  position: absolute;
  background-size: contain;
  background-position: center;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
}
#search_mini_form .icon-close {
  /* undo button */
  appearance: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  /* set styles */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzUiIGhlaWdodD0iNzQiIHZpZXdCb3g9IjAgMCA3NSA3NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuNSAyTDcyLjUgNzJNNzIuNSAyTDIuNSA3MiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
  background-size: 18px;
  height: 44px;
  padding: 0.5em;
  right: -54px;
  width: 44px;
  display: none;
}

body.autocomplete-open #search_mini_form .icon-close {
  display: block;
}

#search_mini_form .icon-close:focus-visible {
  outline: var(--color-blue) auto 1px;
}
#search_mini_form .icon-search {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjcuNSIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNMTMuNSAxMy41TDIwIDIwIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==);
  left: 12px;
}
@media only screen and (min-width: 768px) {
  #search_mini_form .icon-close {
    display: none;
    right: 5px;
  }

  .open #search_mini_form .icon-close {
    display: block;
  }
}

#autocomplete-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  position: fixed;
  inset: 0;
  z-index: 1000;
}

#autocomplete-overlay.show {
  display: block;
}
</style>

<!-- This can't be scoped because vue-loader is buggy. -->
<style>
body.autocomplete-open {
  max-height: 100vh;
  overflow: hidden;
}

body.autocomplete-open .page-header {
  background-color: var(--color-white);
  z-index: 1001;
}
</style>
